import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const Card = memo(function Card({
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>

      <Wrapper>
        {Label ? (
          <FadeIn>
            <Label>{label}</Label>
          </FadeIn>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description
              className="item-desc"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 6.75rem);
  margin: 6.75rem 6.75rem 0 0;

  @media (max-width: 1199px) {
    width: 100%;
    margin: 6.25rem 0 0;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    &:nth-of-type(2) {
      margin-top: 3.75rem;
    }
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-bottom: 75%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    height: 17.5rem;
    padding-bottom: 0;
  }
`

const Wrapper = styled.div`
  padding: 3.1875rem 4.5rem 0;
  position: relative;
  &:after {
    content: '';
    width: 50%;
    height: 75%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    padding: 2.5rem 0 0 3.75rem;
    &:after {
      display: none;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.1875rem;
  font-weight: 300;
  line-height: 1.875rem;
  opacity: 0.3;
  position: absolute;
  top: 3.1875rem;
  left: 0;
  white-space: nowrap;
  writing-mode: vertical-rl;
  transform: scaleX(-1) scaleY(-1);

  @media (max-width: 1199px) {
    top: 3rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.125rem;
  font-weight: 300;
  line-height: 2.625rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    font-size: 1.125rem;
    line-height: 2.125rem;
    margin-top: 1.875rem;
  }
`
