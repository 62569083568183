import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'
import Masonry from 'react-masonry-component'

import { Card, Props as CardProps } from './Card'
import { Icon } from './Icon'

export interface Props {
  cards: CardProps[]
  label?: string
  title?: string
}

export const Cards = memo(function Cards({ cards, label, title }: Props) {
  if (cards.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper
        options={{
          gutter: 108,
          percentPosition: true,
          transitionDuration: 0,
        }}
      >
        <Head>
          <Icon />

          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}

          {title ? (
            <FadeInUp>
              <Title>
                <InnerTitle>{title}</InnerTitle>
              </Title>
            </FadeInUp>
          ) : null}
        </Head>

        {cards.map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 5.75rem auto 12.5rem;
  padding: 0 8.333vw;

  @media (max-width: 1199px) {
    margin: 6.25rem auto;
    padding: 0 1.5rem;
  }
`

const Wrapper = styled(Masonry)<MasonryProps>`
  margin-right: -6.75rem;

  @media (max-width: 1199px) {
    margin-right: 0;
  }
`

const Head = styled.div`
  width: calc(50% - 6.75rem);
  margin-top: 6.75rem;
  padding: 0 4.653vw 12.5rem 4.653vw;

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 0;
    padding: 0;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    text-align: center;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.1875rem;
  font-weight: 300;
  line-height: 1.875rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  max-width: 25rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 4.375rem;
  white-space: nowrap;
  &:after {
    content: '';
    display: inline-block;
    width: 2.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    margin-left: 0.9375rem;
    transform: translateY(-0.75rem);
  }

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.5rem;
    line-height: 2.8125rem;
    white-space: normal;
    &:after {
      width: 1.875rem;
      transform: translateY(-8px);
    }
  }
`

const InnerTitle = styled.span`
  display: inline;
  white-space: normal;
`

interface MasonryProps {
  children?: React.ReactNode | React.ReactNode[]
}
